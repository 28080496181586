import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import SEO from '../../components/SEO';
import Header from '../../components/Header';
import ContentRow from '../../components/ContentRow';
import { commonHeaderColor } from '../../util';
import { FindSales as FindSalesIcon, BuyOnline as BuyOnlineIcon } from '../../images/svg-icons';


const Page = ({ data }) => (
  <>
    <SEO
      title="Spend Neosurf - anonymous way to pay online"
      description="Neosurf vouchers are widely accepted by thousands of merchants all over the world."
      alternateLanguagePages={[
        {
          path: 'buy-neosurf/spend-neosurf',
          language: 'en'
        },
        {
          path: 'acheter-neosurf/spend-neosurf',
          language: 'fr'
        },
        {
          path: 'buy-neosurf/spend-neosurf',
          language: 'de'
        }
      ]}
    />
    <Header backgroundColor={commonHeaderColor} />
    <ContentRow backgroundColor={commonHeaderColor} padV>
      <div className="grid c-2">
        <div>
          <a href="https://bit.ly/2AlU6Ch">
            <Img fluid={data.myneosurf.childImageSharp.fluid} alt="MyNeoSurf Logo" />
          </a>
        </div>
        <div>
          <div className="contentBox textAlignCenter">
            <h1 className="smaller h3 smaller">Spend Neosurf</h1>
            <p className="textColorDark">Spend Your Neosurf Voucher - Switch To Crypto!</p>
          </div>
        </div>
      </div>
    </ContentRow>
    <ContentRow padV>
      <p className="textAlignCenter"><FindSalesIcon width="100" /></p>
      <p className="textColorDark textAlignCenter"><span className="sectionMarker">1</span> Find your nest casino platform</p>
      <div className="grid c-2">
        <div className="textAlignCenter">
          <p><span className="sectionMarker">2</span></p>
          <p className="textColorDark"><strong>Select Neosurf</strong> during checkout</p>
        </div>
        <div className="textAlignCenter">
          <p><span className="sectionMarker">3</span></p>
          <p className="textColorDark"><strong>Enter your pincode</strong> to pay</p>
        </div>
      </div>
      <p className="textAlignCenter"><BuyOnlineIcon width="100" /></p>
      <p className="textColorDark textAlignCenter">Simply deposit with crypto to get started</p>
      <p className="textAlignCenter"><a className="button">Make crypto deposit</a></p>
    </ContentRow>
    <ContentRow padV>
      <p className="textColorDark">Already have a Neosurf voucher, and you are ready to use it? Neosurf vouchers are widely accepted by thousands of merchants all over the world, and they offer a secure and anonymous way to pay online. Whether you are topping up a gaming account or doing some other online transaction, using Neosurf is as simple as entering the unique code that comes with the voucher at the checkout.</p>
      <p className="textColorDark">Neosurf vouchers are quite popular among gamers and entertainment enthusiasts, which is why many online gaming platforms accept Noesurf. They allow users to fund their accounts quickly and easily without the need for a credit card or bank account. All you have to do is navigate to the payment section, select Neosurf as your payment method, and input your code. The funds are instantly applied, which gives you more time to enjoy your favorite games.</p>
      <p className="textColorDark">If you are looking for even more flexibility, you can explore Bitcoin. It is a globally accepted digital currency, and it is a fantastic alternative for users who want more versatility in their online payments. It is particularly useful on platforms like LuckyStar.io, where you can deposit funds instantly and securely using Bitcoin. Here is how to use Bitcoin if you are considering this option:</p>
      <ol>
        <li>
          <p className="textColorDark">Set up a Bitcoin wallet - Your wallet is where you will store your cryptocurrency, including Bitcoin. There are various wallets to choose from, such as online platforms, apps, and even hardware wallets that offer the most security.</p>
        </li>
        <li>
          <p className="textColorDark">Buy Bitcoin - Purchase Bitcoin from trusted exchanges using your preferred payment method.</p>
        </li>
        <li>
          <p className="textColorDark">Spend Bitcoin - Use Bitcoin to make payments on platforms that accept cryptocurrency, such as online casinos, shopping sites, and more.</p>
        </li>
      </ol>
      <p className="textColorDark">Bitcoin is also an ideal solution if you have been struggling to find Neosurf vouchers in your area or if you are looking for a payment method that is even more universally acceptable. With Bitcoin, you can easily bypass region limitations and enjoy the convenience of instant, secure transactions no matter where you are in the world.</p>
      <p className="textColorDark">For those who already have a Neosurf voucher, it remains an amazing payment method for specific platforms. However, if you are interested in exploring a digital-first solution, Bitcoin is the best choice. It offers all the privacy and security benefits that you are used to with Neosurf vouchers while also offering additional features such as global accessibility.</p>
      <p className="textColorDark">Whether you are spending your Neosurf voucher or looking to switch to Bitcoin, you will find a lot of options out there. Explore the benefits of both methods today and discover how easy and secure online payments can be. And if you want to learn more about Bitcoin payments, visit LuckyStar.io.</p>
    </ContentRow>
  </>
)

export default Page;

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    myneosurf: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "buy_neosurf/hero.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 899,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
